
@import "@blueprintjs/core/lib/scss/variables"

.textInputField
  input
    border-radius: 3px

  [readonly]
    color: #666

  input::placeholder
    color: #bbb

  :global
    .error
      color: red
      font-family: $pt-font-family

    .bp4-input-group .bp4-tag
      margin: 3px
      padding: 0px
      span
        margin: 4.5px 6px 3.5px 6px
