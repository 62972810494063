.employeeForm {
  .row {
    align-items: flex-end;
    width: 100%;
  }

  .row>div {
    flex: 1;
  }

  .row>span {
    margin: 0 8px 23px 8px;
  }
}