.timeRangeField {
  div[class~="bp4-timepicker-input-row"] {
    white-space: nowrap;
    box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 0.2px 0.5px rgba(17, 20, 24, 0.1);
    border-radius: 3px;

    input {
      margin: 2px;
      border: none;
      width: 22px;
      color: #626262;
      font-family: 'Lato';
      font-size: 13px;

      &[class~="bp4-timepicker-hour"] {
        text-align: right;
      }
    }

    span[class~='bp4-timepicker-divider-text'] {
      color: #5d5d5d;
    }
  }

  div[class="bp4-timepicker error"] {
    div[class~="bp4-timepicker-input-row"] {
      box-shadow: inset 0 0 0 1px #cd4247b1, 0 0.2px 0.5px #cd42477b;

      input {
        color: #cd4246;
      }

      span[class~='bp4-timepicker-divider-text'] {
        color: #cd4246;
      }
    }
  }

  button[class~="bp4-minimal"] {
    margin-inline-end: -10px;
    margin-inline-start: 3px;
    min-height: unset;
    min-width: unset;

    span[class='bp4-icon bp4-icon-cross'] {
      svg {
        width: 9px;
        height: 9px;
        color: #696969;
      }
    }
  }
}

.noFirstValue {
  div[class~="bp4-timepicker"]:nth-of-type(1) {
    div[class~="bp4-timepicker-input-row"] {
      input {
        color: transparent;
      }

      input:focus {
        color: #5d5d5d;
      }
    }
  }
}

.noSecondValue {
  div[class~="bp4-timepicker"]:nth-of-type(2){
    div[class~="bp4-timepicker-input-row"] {
      input {
        color: transparent;
      }

      input:focus {
        color: #5d5d5d;
      }
    }
  }
}