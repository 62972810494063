
.toaster

  div[class~="bp4-toast"]
    margin: 4px
    min-width: 200px !important
    position: fixed !important
    border-radius: 4px
    padding: 2px 3px 0px 3px

  button[aria-label="Close"]
    border-radius: 99px
    margin-inline-end: 1px

  :global
    .bp4-toast > .bp4-icon
      margin: auto 0px auto 13px
    .rtl .bp4-toast > .bp4-icon
      margin: auto 13px auto 0px
    .bp4-toast > .bp4-button-group
      padding: 0px 0px 2px 0px
      margin: auto 0px