.categoryCards {
  padding: 10px 15px 5px 15px;

  div[class~="bp4-card"] {
    width: calc(100% - 32px);
    box-shadow: none;
    border: 1px #e0e0e0 dashed;
    height: 74px;
    margin-bottom: 10px;
  }

  div[class~="bp4-card"]:hover {
    cursor: pointer;
  }

  .listItemActions {
    margin-top: -5px;
    margin-inline-end: -7px;
    margin-bottom: 0px;
    margin-inline-start: 6px;

    button {
      span[class~="bp4-icon"] {
        color: #aaaaaa;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

.selectCategoryCard {
  margin: 0px 5px 10px 5px;
  width: auto;
}