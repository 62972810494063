.checklist>ul>li {
  padding-top: 10px;
  font-weight: bold;
  font-size: 15px;
}

.checklist>ul>ul {
  padding-inline-start: 20px;
  font-weight: normal;
  font-size: 14px;
}
.checklist>ul>ul>li {
  padding-top: 5px;
}

.checklist>ul>ul>li>span {
  margin-inline-start: 7px;
}