.appItem {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.appItem>b {
  margin-top: 10px;
  text-align: center;
}
.appItem>p {
  margin-top: 5px;
  text-align: center;
}
.appItem>a {
  margin-top: 10px;
}