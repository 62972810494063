.pageheader
  width: 100%
  max-width: 100vw
  padding: 20px 40px 10px 40px
  display: flex
  justify-content: flex-start
  flex-flow: row nowrap
  align-items: flex-start
  position: sticky
  top: -5px
  background-color: white
  z-index: var(--page-header-z-index)
  box-shadow: none

  @media (max-width: 800px)
    padding-inline-start: 30px
    padding-inline-end: 30px

  @media (max-width: 500px)
    padding-inline-start: 20px
    padding-inline-end: 20px

  &[stuck]
    box-shadow: 0 2px 5px -2px #e6e6e6
    transition: box-shadow 500ms, padding 100ms linear

  &>button[class~="back"]
    margin-top: -2px
    margin-inline-start: -35px
    margin-inline-end: 4px

    @media (max-width: 800px)
      margin-inline-start: -25px

    @media (max-width: 500px)
      margin-inline-start: -15px

  .icon
    margin: 5px 8px 0px 0px
    display: flex
    justify-content: center

  h1
    margin: 0px 10px 2.5px 0px
    font-size: 1.6em
    height: 1.4em
    font-weight: 600
    white-space: nowrap
    text-overflow: ellipsis
    overflow-x: hidden
    flex-grow: 0
    flex-shrink: 100

  .searchInput
    margin-top: 1px
    margin-inline-start: 10px
    margin-inline-end: 10px
    flex-grow: 1

    input
      height: 31px
      max-width: 64px
      box-shadow: inset 0 0 0 1px #bdbdbd !important
      transition: max-width 0.25s ease-in-out
      &::placeholder
        color: #bdbdbd
      &:not(:placeholder-shown),
      &:focus
        max-width: 300px
        box-shadow: inset 0 0 0 1px #a1a1a1 !important

    &>span[class~='bp4-icon']
      color: #bdbdbd
    &:focus-within>span[class~='bp4-icon']
      color: #a1a1a1

  &>*
    margin-inline-start: 7px

    @media (max-width: 700px)
      margin-inline-end: 4px

    @media (max-width: 500px)
      margin-inline-end: 0px

  .calloutIcon
    span
      cursor: pointer
      width: 32px
      height: 32px
      border-radius: 99px
      padding: 0px
      box-shadow: 1px 1.5px 4px 1px rgb(17 20 24 / 10%)
      display: flex
      justify-content: center
      align-items: center
    >span[class~="bp4-intent-warning"]
      border: 1px solid #c88538
      background-color: rgba(200, 118, 25, 0.1)
    >span[class~="bp4-intent-primary"]
      border: 1px solid #6ea3ec
      background-color: rgba(45, 114, 210, 0.1)

.pageHeaderCallouts
  margin: 0px

  .calloutWrapper
    height: auto
    transform: scaleY(1)
    margin: 5px 0px 15px 0px
    transition: transform .26s ease

  .calloutWrapper[class~="collapsed"]
    height: 0px
    transform: scaleY(0)
    transform-origin: top
    transition: transform .26s ease

:global
  .searchInputPlaceholder
    margin-inline-end: auto

  .bp4-navbar-heading
    margin-right: unset !important
    margin-inline-end: 5px