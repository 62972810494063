
@import "@blueprintjs/core/lib/scss/variables"

.rangeField
  :global
    .error
      color: red
      font-family: $pt-font-family

    div.bp4-form-content
      width: 100%
      padding: 0 12px 0 15px

      div.bp4-slider
        width: 100%

.muted
  :global
    div.bp4-form-content
      div.bp4-slider
        pointer-events: none
        cursor: default
        div.bp4-slider-track
          div
            cursor: default
          span
            cursor: default
            box-shadow: 0 0 0 0.1px
          div.bp4-slider-axis
            div
              cursor: default
