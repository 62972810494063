.calendar {
  font-family: 'Nunito Sans';
  width: 100% !important;

  li[class="Cal__Weekdays__day"] {
    background-color: #2872d8;
    padding: 5px 0;
  }

  li[class="Cal__Day__root Cal__Day__enabled"] {
    font-size: 13px;
    font-weight: 500;
    color: #747474;
    box-shadow: none;
  }

  li[class="Cal__Day__root Cal__Day__today Cal__Day__selected Cal__Day__enabled"],
  li[class="Cal__Day__root Cal__Day__selected Cal__Day__enabled"] {
    div[class='Cal__Day__selection'] {
      background-color: #f9bf3b !important;
      border-radius: 5px;
      // width: 47px;
      // height: 47px;
      // margin-top: -22px;
      // margin-inline-start: -22px;
    }
  }

  li[class="Cal__Day__root Cal__Day__today Cal__Day__enabled"]:hover::before,
  li[class="Cal__Day__root Cal__Day__today Cal__Day__enabled"]::before {
    box-shadow: none;
    border: none;
    border-radius: 5px;
    // width: 47px;
    // height: 47px;
    // margin-top: -22px;
    // margin-inline-start: -22px;
  }

  li[class="Cal__Day__root Cal__Day__enabled"]:hover::before {
    border-radius: 5px;
    // width: 47px;
    // height: 47px;
    // margin-top: -22px;
    // margin-inline-start: -22px;
  }
}

div[class="tagList"] {
  margin-top: 10px;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: start;
  gap: 5px;

  span[class~="bp4-tag"] {
    background-color: #f9bf3b;
    // color: #404040;
    padding: 5px 8px;
    border-radius: 5px;

    button {
      // color: #656565;
    }
  }
}