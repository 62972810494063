.productCard {
  width: 100%;
  position: relative;
  height: 72px;
  display: flex;
  flex-direction: row;

  &[class~="error"] {
    box-shadow: 0 0 0 1px #cd424661;
  }

  div[class~="flex-column"] {
    width: 100%;
    height: 100%;
    padding: 6px 8px;

    h4 {
      margin: 0px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      svg {
        margin: -2.5px 5px 0px 0px;
      }
    }

    i {
      margin-top: 2px;
      font-size: 12px;
      color: #bbb;
      font-style: normal;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.productIcon {
  position: absolute;
  inset-inline-end: 0;
  height: 100%;
  width: 84px;
  border-inline-start: 1px solid #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fcfcfc;
  border-radius: 6px;
}

.productIcon svg {
  width: 45px;
  height: 40px;
  color: #bbb;
}

.productImage {
  width: 84px;
  height: 72px;
  position: absolute;
  inset-inline-end: 0;
  border-radius: 6px;

  &[data-available="false"] {
    filter: grayscale(1);
  }
}