

.branchCard {
  width: 100%;
  position: relative;
  height: 85px;
  display: flex;
  flex-direction: row;

  div[class~="flex-column"] {
    width: 100%;
    height: 100%;
    padding: 8px 8px 6px 105px;

    h4 {
      margin: 0px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      svg {
        margin: -2.5px 5px 0px 0px;
      }
    }
  }
}

.branchImage {
  width: 95px;
  height: 85px;
  object-fit: cover;
  position: absolute;
  left: 0;
  border-radius: 6px 0px 0px 6px;

  &[data-online="false"] {
    filter: grayscale(1);
  }
}

.branchIcon {
  position: absolute;
  left: 0;
  height: 100%;
  width: 95px;
  border-right: 1px solid #eee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.branchIcon svg {
  width: 45px;
  height: 40px;
  color: #bbb;
}