.tableCard {
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: column;

  >div:first-child {
    padding: 5px 10px 0px 10px;
  }

  h4 {
    margin: 8px 10px 6px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
  }

  div[class~="icon"] {
    margin-top: -5px;
    margin-inline-start: 9px;
    margin-inline-end: -2px;

    svg {
      color: #a4a4a4;
      width: 12px !important;
      height: 12px !important;
    }
  }

  .avatar,
  .avatar>img {
    border-radius: 5px;
  }
}