
.card
  margin-top: 10vh
  margin-bottom: 25vh
  height: fit-content

  .container
    display: flex
    flex-direction: row

    .laading
      margin: 20px 30px 10px 30px
      min-width: 250px

    .form
      margin: 20px 30px 10px 30px
      min-width: min(80vw, 350px)

      button
        margin-top: 25px