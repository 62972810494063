.manageSubscription {
  min-width: 200px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  img {
    height: 100px;
    border-radius: 10px;
  }

  >h2 {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  >span {
    color: #a4a4a4;
    margin-bottom: 30px;
  }

  p {
    color: #606060;

    span {
      margin: 0px 8px 2px 5px;
    }
  }

  button {
    margin-top: 30px;

    span {
      font-weight: bold;
    }
  }
}