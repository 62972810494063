.observer {
  &.sticky {
    position: sticky;
  }
}

.panel {
  position: fixed;
  top: 0px;
  background-color: transparent;
  z-index: 4;
}

.panel[stuck] {
  box-shadow: 0 2px 5px -2px #e6e6e6;
  background-color: white;
  transition: box-shadow 500ms, padding 100ms linear;
}

.stickyTabList {
  div[class~="bp4-tab-list"] {
    position: sticky;
    z-index: 6;
  }
}

.removePageHeaderShadows {
  box-shadow: none !important;
}