.logoFrame {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
  padding: 20px;
  background-color: #ffedd8;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

.logoFrame > a > img {
  border-radius: 10px;
}

.branchFrame {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
  margin-bottom: 15px;

  b {
    align-self: flex-start;
  }

  button {
    min-width: 180px !important;
  }
}
