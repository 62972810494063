
@import "@blueprintjs/core/lib/scss/variables"

.exPhoneField
  input
    border-radius: 3px
    padding-inline-start: 52px !important

  [readonly]
    box-shadow: inset 0 0 0 1px rgba(39, 44, 51, 0.15) !important
    color: #666

  input::placeholder
    color: #bbb

  select
    text-align: center
    text-align-last: center
    color: #464646
    width: 45px
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    padding: 8.5px 5px 8.5px 5px
    margin: -2px 0px 0px 1px
    outline: none
    border: 0px solid
    border-right: 1px solid #dcdcdc
    border-radius: 3px 0px 0px 3px
    background-color: #f7f7f7 !important
    cursor: pointer

  select[disabled]
    cursor: default

  select[class~="loading"]
    cursor: progress
    color: #7f7f7f

  :global
    .error
      color: red
      font-family: $pt-font-family

    .bp4-input-group .bp4-tag
      margin: 3px
      padding: 0px
      span
        margin: 4.5px 6px 3.5px 6px

:global
  .rtl select.ext-phone-select
      border-left: 1px solid #dcdcdc
      border-right: none
      margin: 1.2px 1px 0px 0px !important
      border-radius: 0px 3px 3px 0px
