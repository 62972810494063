
.cardItem
  display: flex
  align-items: center
  margin: 0px 0px 5px 0px

  p
    margin: 0px
    font-size: 14px

  div[class="icon"]:not(:empty)
    padding-inline-end: 10px
    svg
      color: #777777
      min-width: 14px
      min-height: 14px

  div[class="right"]
    margin-inline-start: auto
    padding-inline-start: 5px
    text-align: right
