/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
@import "@blueprintjs/core/lib/scss/variables";

.formField {

  &[class~="horizontal"] {
    flex-direction: row;

    label {
      flex: 1;
    }

    div[class~="bp4-form-content"] {
      div[class="bp4-collapse"] {
        div[class="bp4-collapse-body"] {
          display: flex;
          flex-direction: row;
        }
      }
    }
  }
}

:global .error {
  color: $pt-intent-danger
}