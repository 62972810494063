.countryDropdownField {
  div[class~="bp4-form-content"] {
    position: relative;
  }
}

.refreshButton[class~="bp4-button"] {
  width: calc(100% - 2px);
  height: 37px;
  background-color: white;
  margin-top: -61px;
  margin-inline-start: 1px;
  margin-inline-end: 0px;
}