
.container
  border: 1px solid #b8b8b8
  border-radius: 99px
  margin-top: 5px
  cursor: pointer
  display: grid
  background-color: #f9bf3b

  span
    color: #6f6f6f
    font-weight: 400
    grid-column: 1
    grid-row: 1
    text-align: center
    margin-top: auto
    margin-bottom: auto

  img
    border-radius: 99px
    grid-column: 1
    grid-row: 1
    margin: -0.5px

.grayscale
  background-color: #bbb

  span
    color: #fff

  img
    filter: grayscale(1)
