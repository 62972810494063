

.workspace
  width: 100%
  max-width: 1280px
  min-height: 100vh
  display: flex
  flex-direction: column
  align-self: center

  .body
    width: 100%
    min-height: calc(100vh - 52px)
    display: flex
    flex-direction: row
    background: white
    border-left: 1px solid rgba(0, 0, 0, 0.1)
    border-right: 1px solid rgba(0, 0, 0, 0.1)

    .sidebar
      width: 230px

    .content
      width: calc( 100% - 230px )
      display: flex
      flex-direction: column
      border-inline-start: 1px solid #eee

      @media (max-width: 1000px)
        width: 100%

    .businessCard
      margin-bottom: 30vh

  :global
    .bp4-topbar-heading
      .logo
        margin-top: 5px
      span
        margin-inline-start: 5px

.dontDisplayOnStartup
  display: flex
  flex-direction: row
  gap: 6px
  justify-content: flex-end
  align-items: center

.dontDisplayOnStartup>input
  margin-top: 1px
