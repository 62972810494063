

.appsidebar[class~="bp4-menu"]
  width: 100%
  position: sticky
  top: 0
  align-self: flex-start

  &>li
    margin: 1px 1px
    color: #2e4a5c
    font-size: 13px

  &>li[class~="bp4-menu-header"]
    margin-top: 3px
    h6
      font-weight: 600

  &>li svg
    color: #2e4a5c

  :global
    .bp4-icon.bp4-icon-translate
      padding: 0px 1px 0px 5px
