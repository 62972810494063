.categoryCard {
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: column;

  h4 {
    margin: 5px 10px 5px 10px;
  }

  p {
    margin: 13px 10px 5px 10px;
    color: #8a8a8a;
    font-family: 'Nunito Sans';
    font-size: 10pt;
  }
}

.productImages {
  display: flex;
  flex-direction: row;
  margin: 5px 9px 0px 9px;
  overflow: hidden;

  img {
    width: 35px;
    height: 35px;
    border-radius: 8px;
    margin-inline-end: 4px;
    object-fit: cover;

    &[data-available="false"] {
      filter: grayscale(1);
    }
  }
  svg {
    width: 25px;
    height: 25px;
    color: #8a8a8a;
    margin-top: 6px;
    margin-inline-start: 2px;
  }
}