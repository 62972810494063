.salesReportBody {
  color: #737373;

  h3,
  h4 {
    margin-bottom: 10px;
  }
}

.dateRangeInput {
  div[class="bp4-input-group"] {
    flex-shrink: 1;

    input {
      width: 100px;
    }
  }
}

.detailedReportTable {
  width: 100%;
}

.detailedReportTable td {
  padding: 4px 6px;
  font-size: 13px;
}

.detailedReportTable>thead {
  font-weight: bold;
}

.detailedReportTable>thead>tr>td:nth-child(2),
.detailedReportTable>thead>tr>td:nth-child(3),
.detailedReportTable>thead>tr>td:nth-child(4) {
  text-align: right;
}

.detailedReportTable>thead>tr>td {
  border-bottom: 1px solid #eee;
}

.detailedReportTable>tbody>tr>td:nth-child(2),
.detailedReportTable>tbody>tr>td:nth-child(3),
.detailedReportTable>tbody>tr>td:nth-child(4) {
  text-align: right;
}

.dateRangeButton{
  margin-top: 1px;
}