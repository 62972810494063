

.pagecontent
  padding: 5px 40px 20px 40px
  width: 100%
  height: 100%
  max-width: 100vw

  @media (max-width: 800px)
    padding-inline-start: 30px
    padding-inline-end: 30px

  @media (max-width: 500px)
    padding-inline-start: 20px
    padding-inline-end: 20px
