
.cardheader
  padding: 4px 5px 2px 5px
  background-color: #f6f6f6
  border-radius: 8px 8px 0px 0px
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  min-height: 36px

  h4
    color: #525252
    flex: 3
    margin: 0px 0px 2px 0px
    margin-block-start: 1.20
    margin-block-end: 1.20
    text-align: center
    font-weight: 600
    white-space: nowrap
    overflow-x: hidden
    text-overflow: ellipsis

  div
    margin: 0px 7px

  div[class="right"]
    justify-content: flex-end

  button
    margin: -3px -6px -1px 0px