.option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  label {
    font-size: 12px;
  }

  span {
    font-size: 12px;
  }

  span[data-selected=true] {
    font-weight: bold;
    color: #b65959;
  }
}