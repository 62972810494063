.card {
  margin-top: 10vh;
  margin-bottom: 15vh;
  height: fit-content;
  align-self: center;
}

.logobox {
  border-left: 1px solid #eee;
  background-color: #f6f6f6;
  max-width: 180px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;

  img {
    max-width: 100px;
    width: 90%;
  }
}

@media (max-width: 500px) {
  .logobox {
    display: none;
  }
}

.form {
  padding: 5px
}

.formSection {
  width: 270px;
  height: 190px;
  overflow: hidden;
  padding-inline-start: 5px;
  padding-inline-end: 5px;
  transition: height 200ms ease-in-out;
  display: flex;
  flex-direction: column;
}

.otpOrLabel {
  align-self: center;
  color: #888888;
  margin-top: -9px;
}