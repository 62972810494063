.topbar
  background-color: #F5F5F5 !important
  border-bottom: 2px solid #f9bf3b
  box-shadow: none !important
  height: 52px !important
  padding: 0px 10px 0px 10px !important
  display: flex
  justify-content: space-between
  gap: 10px

  :global
    .bp4-align-left
      gap: 10px

    .bp4-popover2-target
      margin: 0px

      button
        box-shadow: none

    .logo
      cursor: pointer
      height: 35px
      margin-top: 4px
      margin-inline-start: -5px
      margin-inline-end: 10px
      // @media (max-width: 600px)
      //   width: 130px
      // @media (max-width: 400px)
      //   width: 100px

    .logo_title
      cursor: pointer
      height: 33px
      margin-bottom: 1px

    .bp4-menu
      background: transparent

    .businessAvatar
      margin-bottom: 2px
      margin-inline-start: 10px

    .userAvatar
      margin-bottom: 4px
      margin-inline-start: 10px
