
@import "@blueprintjs/core/lib/scss/variables"

.dropdownField
  select
    width: 100%
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    display: inline-block
    padding: 10px 10px
    padding-inline-end: 25px
    outline: none
    border: 0px solid
    border-radius: 3px
    box-shadow: inset 0 0 0 1px rgba(17,20,24,.2), 0 .2px .5px rgba(17,20,24,.1)
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%)
    background-position: calc(100% - 15px) calc(1em + 3px), calc(100% - 10px) calc(1em + 3px), calc(100% - 2.5em) 0.5em
    background-size: 5px 5px, 5px 5px, 1px 1.5em
    background-repeat: no-repeat
    background-color: #fefeff !important

.readOnly
  select
    opacity: 1
    color: $pt-text-color
    background-image: none

.placeholder
  select
    color: #7f7f7f

:global
  .rtl .dropdown-form-field select
    background-position: calc(15px) calc(1em + 3px), calc(20px) calc(1em + 3px), calc(2.5em) 0.5em
