
@import "@blueprintjs/core/lib/scss/variables"

.numericinput
  [readonly]
    // box-shadow: none
    color: #666

  input::placeholder
    color: #bbb

  :global
    .error
      color: red
      font-family: $pt-font-family

    div.bp4-form-content
      width: 100%

      div.bp4-numeric-input
        width: 100%

        div.bp4-input-group
          width: 100%

          input
            width: 100%

        div.bp4-button-group
          position: absolute
          inset-inline-end: 0.5px
          top: 0.5px
          bottom: 0.5px

          button
            width: 20px 
            min-width: 0px
            z-index: 20
            margin: 0px

            span
              svg
                width: 13px
                height: 13px
