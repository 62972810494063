.openingHoursField {
  margin: 0px !important;

  div[class~="form"] {
    flex-direction: row;
    justify-content: space-around;
    padding: 5px 10px 10px 10px !important;
    gap: 10px;

    div[class~="bp4-form-group"] {
      margin: 0px;

      label[class~="bp4-label"] {
        span {
          font-size: 11px !important;
        }
      }

      span[class~="bp4-timepicker-divider-text"] {
        padding-bottom: 2px;
      }

      input {
        height: 20px;
        padding: 2px 4px 0px 4px;
        font-size: 13px !important;
        max-width: 24px;
      }
    }
  }

  :global {
    .bp4-timepicker .bp4-timepicker-divider-text {
      width: 8px;
    }
  }
}