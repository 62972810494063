.catalogCard {
  width: 100%;
  height: 106px;

  h4 {
    margin: 5px 10px 5px 10px;
  }

  p {
    margin: 13px 10px 5px 10px;
    color: #8a8a8a;
    font-family: 'Nunito Sans';
    font-size: 10pt;
  }
}

.catalogImages {
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;

  .categoryImages {
    width: 72px;
    height: 71px;
    flex: 0 0 auto;
    padding: 3px;
    margin: 3px 0px 3px 0px;
    margin-inline-start: 5px;
    border: 1px dashed #d8d8d8;
    border-radius: 5px;
    gap: 3px;

    img {
      width: 30px;
      height: 30px;
      border-radius: 8px;
      object-fit: cover;

      &[data-available="false"] {
        filter: grayscale(1);
      }
    }
  }
}