.otpInput {
  margin-top: -2px;
  width: 90px;

  >span {
    padding-top: 2px;
  }

  >input {
    padding: 0px 5px 0px 28px !important;
    background-color: #f4f4f4;
  }
}