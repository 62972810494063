.pageContentLoader {
  padding: 45px 20px 40px 20px;

  svg:first-child {
    margin-bottom: 50px;
  }

  svg {
    width: 100%;
  }
}