.imageFrame {
  padding-top: 30px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.imageFrame>div {
  height: 520px !important;
  width: 420px;
}

.categoryFrame {
  margin-top: 20px;
  padding: 10px;
  border: 1px dashed #c4c3c3;
  border-radius: 9px;
  min-height: 150px;
}

.categoryFrame>input {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
  border: none;
  width: calc(100% - 20px);
}