.optionRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  div[class~="form"] {
    width: 100%;
  }

  button[class~=bp4-button] {
    margin-inline-start: 10px;
    margin-inline-end: -10px;

    span {
      svg {
        width: 13px;
        height: 13px;
      }
    }
  }
}