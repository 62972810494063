.merchantAccountCard {

  .paymentMethods {
    padding: 10px 15px;
    gap: 20px;
    // justify-content: center;

    svg {
      color: #aaa;
    }

    svg[data-enabled=true] {
      color: #f9bf3b
    }
  }

  :global {
    .description {
      color: #7f7a7a;
      font-size: 13px;
    }
  }
}