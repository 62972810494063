.callout[class~='bp4-callout'] {
  border-radius: 9px;

  &[class~="bp4-intent-warning"] {
    border: #935610 1px solid;

    a {
      color: #c34010;
    }

    .actions {
      button {
        color: #935610;
      }
    }
  }

  h4[class~='bp4-heading'] {
    font-weight: 600;
    font-size: 16px;
  }

  .actions {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      margin-inline-end: 10px;
      font-weight: 600;
    }
  }
}