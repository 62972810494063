.cartItemCards {
  padding: 10px 15px 5px 15px;
  cursor: pointer;

  div[class~="bp4-card"] {
    box-shadow: none;
    border: 1px #e0e0e0 dashed;
    margin-bottom: 10px;
  }

  .optionGroups {
    padding: 0px;
    margin: 10px 0px 0px 0px;
    padding: 0px;
    min-width: unset;
    width: auto;
    display: flex;
    flex-direction: column;

    div[class~="bp4-form-group"] {
      margin: 0px;

      label[class~="bp4-form-content"] {
        // color: #2d72d2;
        font-size: 12px !important;
      }
    }
  }
}

span.totalPrice {
  color: #b65959;
  font-weight: bold;
  margin: 0px 4px 0px 0px;
  font-size: 16px;
}

.etrField {
  width: 300px;
}

.note {
  background-color: khaki;
  padding: 0px 5px 2px 5px;
  border-radius: 3px;
}

.preferredEtr {
  background-color: khaki;
  padding: 0px 5px 2px 5px;
  margin-inline-end: -5px;
  border-radius: 3px;
}

.mapCard {
  height: 250px;
  margin-bottom: 30px;
}

.orderStatusTag {
  margin-inline-start: 5px;
}

.confirmDialogPreferredEtr {
  width: 100%;
  display: inline-flex;
  gap: 10px;
}

.confirmDialogAdjustEtrBtn {
  margin: 0px -50px 0px -50px;
}

.cancellationReasons {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  div[class="checkbox"] {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  textarea {
    margin-top: 10px;
    height: 70px;
  }
}