
@import "@blueprintjs/core/lib/scss/variables"

.suggestionsfield

  // input:not(:focus)::placeholder
  //   color: $pt-text-color

  :global
    .bp4-input-group .bp4-input-action
      top: 2px

    .bp4-input-left-container
      svg
        color: $pt-code-text-color

    .error
      color: red
      font-family: $pt-font-family
