.orderCard {
  width: 100%;
  min-height: 90px;
  display: flex;
  flex-direction: column;

  h4 {
    margin: 5px 5px 5px 10px;
    display: inline-flex;
    align-items: flex-end;
    line-height: 20px;

    >span[class~="bp4-tag"] {
      margin-inline-start: 5px;
    }
  }

  p {
    width: 100%;
    margin: 0px;
    padding: 1px 10px 2px 10px;
    display: inline-flex;
    align-items: center;
    color: #565656;
    font-family: 'Nunito Sans';
    font-size: 13px;

    span[class~="content"] {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-grow: 0;
      flex-shrink: 1000;
    }

    svg {
      margin-bottom: -3px;
      margin-inline-end: 8px;
    }

    i {
      font-style: normal;
      font-weight: normal;
      color: #a78a7e;
    }
  }
}

.imagesGrid {
  width: 88px;
  height: 88px;
  flex: 0 0 auto;
  padding: 5px;
  margin: 3px 3px 3px 5px;
  margin-inline-start: auto;
  border: 1px dashed #d8d8d8;
  // direction: rtl;
  border-radius: 5px;
  gap: 5px;

  img {
    width: 35px;
    height: 35px;
    border-radius: 8px;
    object-fit: cover;

    &[data-available="false"] {
      filter: grayscale(1);
    }
  }
}

.pickupMethodTag {
  margin-inline-start: 6px;
}

.manualLocationTrackingTag>span{
  flex-grow: 0 !important;
  flex-shrink: 3 !important;
}