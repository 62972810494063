
@import "@blueprintjs/core/lib/scss/variables"

.switchField
  .container
    display: flex
    align-items: center
    flex-direction: row
    gap: 5px

  .label
    flex-wrap: nowrap
    flex-grow: 1
    font-size: 13px
    display: inline-flex

    .tooltip
      line-height: 12px
      padding-inline-start: 5px
      padding-inline-end: 5px

  :global
    .error
      color: red
      font-family: $pt-font-family

    label.bp4-label
      display: none

    div.bp4-form-content
      width: 100%
      padding: 0 2px 0 2px
      min-height: unset

      label.bp4-control.bp4-switch
        margin: 2px 0px
        font-size: 13px

        input:checked ~ .bp4-control-indicator
          background: #f9bf3b

        span.bp4-control-indicator
          margin-top: -0.5px

.readOnly
  div
    label
      pointer-events: none

.disabled
  div
    label
        span[class~='bp4-control-indicator']
          pointer-events: none
        // TODO: get from vars
        // background-color: rgba(143, 153, 168, 0.3) !important

:global
  .rtl label.bp4-control.bp4-switch.bp4-align-right
    margin-left: -9px !important