.subscriptionPlansDialog {
  width: 980px;
  max-width: 95vw;
  max-height: 90vh;

  stripe-pricing-table {
    padding: 10px 0px;
    background-color: white;
    overflow: scroll;
  }
}