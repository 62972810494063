
.eximage.dragging
  border-color: #2c81e9

.eximage
  border: 1px dashed #c4c3c3
  border-radius: 9px
  width: 100%
  display: flex
  justify-content: center
  align-items: center

  div
    display: flex
    justify-content: center
    align-items: center
