.timeInputField {
  div[class~="bp4-timepicker-input-row"] {
    white-space: nowrap;
    box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 0.2px 0.5px rgba(17, 20, 24, 0.1);
    border-radius: 3px;

    input {
      margin: 2px;
      border: none;
      // width: 20px;
      color: #5d5d5d;
      font-family: 'Lato';
      font-size: 13px;

      &[class~="bp4-timepicker-hour"] {
        text-align: right;
      }
    }
  }
}

.noValue {
  div[class~="bp4-timepicker-input-row"] {
    input {
      color: transparent;
    }

    input:focus {
      color: #5d5d5d;
    }
  }
}