.slugPrefix {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 29px;
  margin: 0px 0px 0px 8px;
  color: #848a8d;
}

.qrDialog {
  position: relative;
}

.qrDialog>.saveQrButton {
  transition: opacity 250ms;
}

.qrDialog>svg {
  transition: opacity 250ms;
}

.qrDialog:hover>.saveQrButton {
  opacity: 1;
}

.qrDialog:hover>svg {
  opacity: 0.2;
}

.saveQrButton {
  position: absolute;
  width: 310px;
  height: 310px;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}