.noOptionGroupsBackout {
  margin: 20px 0px 20px 0px;
}

.daysOffPicker {
  div[class="tagList"] {
    margin: 10px;
  }
}

.searchBar {
  position: relative;
  z-index: 1;

  label {
    display: none;
  }

  div[class~='bp4-popover2-target'] {
    position: absolute;
    top: 8px;
    left: 20px;
    right: 20px;

    div[class~='bp4-form-group'] {
      margin-bottom: 2px;

      input {
        border-radius: 10px;
      }
    }
  }
}

.locateButton {
  box-shadow: none !important;
}

.cardHeaderButton[class~=bp4-button] {
  padding: 4px 8px;
  min-height: unset;
}

.tableQrCode {
  position: relative;
  margin: 50px;
}

.tableQrCode>span[class~="bp4-icon"] {
  transition: opacity 250ms;
  position: absolute;
  left: 150px;
  top: 150px;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableQrCode>svg {
  transition: opacity 250ms;
}

.tableQrCode:hover>span[class~="bp4-icon"] {
  opacity: 1;
}

.tableQrCode:hover>svg {
  opacity: 0.2;
}